const INFO = {
	main: {
		title: "Gurpreet Singh",
		name: "Gurpreet Singh",
		email: "gur201preet@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/gurpreet203181",
		linkedin: "www.linkedin.com/in/gur201preet-singh",
	},

	homepage: {
		title: "Gurpreet Singh | Code Artisan & Eco Adventurer.",
		description:
			"As a seasoned developer, I've contributed to a diverse array of projects, constantly pushing the boundaries of technology and design. I take pride in my journey and the strides I've made in the tech community. My work, much of which is open-source, is available for exploration and contribution. If any of my projects pique your interest, I encourage you to delve into the code, propose enhancements, or share your insights. Collaboration is a cornerstone of my professional ethos, and I warmly welcome new ideas and perspectives to foster mutual growth and innovation"},

	about: {
		title: "I'm Gurpreet S. from Italy, Designing the Future",
		description:
		"I have worked on a variety of projects over the years and am proud of my progress. If you're interested in any of my work, please feel free to review the code and suggest any improvements. I enjoy collaborating with others and am always open to new ideas and feedback to help us both grow.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "PayAPP",
			description:
				"PayApp is an innovative mobile payment solution designed to facilitate easy and secure person-to-person money transfers.",
			logo: "./react.png",
			linkText: "View Project",
			link: "https://github.com/gurpreet203181/PayAPP",
		},

		

		{
			title: " SpotifyClone ",
			description:
				"MusicVerse is a comprehensive music streaming app designed to provide an immersive listening experience.",
			logo: "./ionic.png",
			linkText: "View Project",
			link: "https://github.com/gurpreet203181/SpotifyClone",
		},
		{
			title: "Food-delivery App",
			description:
				"QuickEats is a dynamic food delivery app designed to bring your favorite meals directly to your doorstep, quickly and efficiently.",
				logo: "./react.png",
			linkText: "View Project",
			link: "https://github.com/gurpreet203181/Food-delivery",
		},

		{
			title: "Passport-Scanner",
			description:
				"PassportScanner is a streamlined mobile application designed to efficiently scan and retrieve data from the RFID chips embedded in passports.",
			linkText: "View Project",
			logo: "android.png",
			link: "https://github.com/gurpreet203181/Passport-Scanner",
		},

		
	],
};

export default INFO;
