import React from "react";
import { faSchool } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faSchool}
				title="Education"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./education.jpg"
								alt="facebook"
								className="work-image"
							/>
							<div className="work-title">Computer Programming</div>
							<div className="work-subtitle">
							 Georgian College
							</div>
							<div className="work-duration">Toronto, Canada |  2023 - Present</div>
						</div>

						<div className="work">
							<img
								src="./education.jpg"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">IT and Telecommunications</div>
							<div className="work-subtitle">
							Institute Rosselli-Rasetti
							</div>
							<div className="work-duration">Perugia, Italy |  2016 - 2021</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
