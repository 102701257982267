import React from "react";

function article_1() {
	return {
		date: "06/2022 - Present - Remote",
		title: "Freelance Developer, Freelance Contractor Via Upwork",
		description:
			"Developed custom e-commerce sites and mobile applications for international clients, including planning, coding, and deployment.",		
			
			keywords: [
			"React-native",
			"React",
			"Ionic",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 1</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "01/2023 - 08/2023 - Perugia, Italy",
		title: "Mobile application developer, Medialab S.r.l",
		description:
			"Integral role in developing a cloud-based booking solution for vacation rental bookings, streamlining management across diverse platforms including Booking.com, Airbnb, all consolidated into the unified Magellano platform.",
		keywords: [
			"React Native",
			"Ionic",
			".NET Web API",
			"Microsoft SQL Management Server",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 1</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}
function article_3() {
	return {
		date: "10/2021 - 12/2022 - Milan, Italy",
		title: "Web application developer, Techedge S.p.A.",
		description:
			"Integral contributor at an IT Service Management company, driving technological evolution.",
		style: ``,
		keywords: [
			"Java",
			"Spring",
			"Javascript",
			"SAP Commerce"
		],
		body: (
			<React.Fragment>
				<h1>Content of article 2</h1>
			</React.Fragment>
		),
	};
}


const myArticles = [article_1, article_2, article_3];

export default myArticles;
